<template>
  <b-card no-body class="mb-1">
    <header class="p-1" role="tab">
      <b-btn v-b-toggle="toggleId" block href="#" variant="light">
        {{ header }}
      </b-btn>
    </header>
    <b-collapse :id="toggleId" :visible="visible" role="tabpanel">
      <slot v-if="noBody" />
      <b-card-body v-else>
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>
<script>
export default {
  name: "CollapsibleCard",
  props: {
    header: {
      type: String,
      required: true
    },
    noBody: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    toggleId() {
      return "base-card-toggle-" + this.uid;
    }
  }
};
</script>
